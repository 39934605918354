.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Card-section {
  margin: 1;
  padding: 1; 
  outline: '1px solid gray';
}

/* Shine */
.hover14 figure {
	position: relative;
  cursor: pointer;
  -webkit-animation: flip 3s;
  animation: flip 2s;
}
.hover14 figure::before {
	position: absolute;
  margin-top: 2%;
	left: 0%;
	z-index: 2;
	display: block;
	content: '';
	width: 30%;
	height: 100%;
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	-webkit-transform: skewX(5deg);
	transform: skewX(-25deg);
  cursor: pointer;
}
.hover14 figure:hover::before {
	-webkit-animation: shine 1s;
	animation: shine .8s;
  cursor: pointer;
}
@-webkit-keyframes shine {
	100% {
		left: 45%;
	}
}
@keyframes shine {
	100% {
		left: 30%;
	}
}


.Card-Info{
   background: 'white';
   background-color: 'white';
   border-radius: '15px';
   border: '1px solid transparent';
   z-index: 0;
}

@-webkit-keyframes flip {
  from {
    transform: rotateY(90deg)
  }
  to {
    transform: rotateY(0deg)
  }
}
@keyframes flip {
  from {
    transform: rotateY(90deg)
  }
  to {
    transform: rotateY(0deg)
  }
}